import React from "react";
import Layout from '../components/layout'
import Icons from '../components/icons'
import Cursor from '../components/cursor'
import Form from '../components/form'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'


class RootIndex extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modalVisible: false,
			loaded: false
		};
		this.setModalVisible = this.updateStateModal.bind(this);
		this.containerRef = React.createRef();
	}

	updateStateModal = (state) => {
		this.setState({ modalVisible: state })
	};

	componentDidMount() {
		this.setState({ loaded: true })
	}



	render() {
		return (
			<>
			<LocomotiveScrollProvider options={{ smooth: true, tablet: { smooth: true }, smartphone: { smooth: false }}} watch={[]} containerRef={this.containerRef}>
					<main id="top" data-scroll-container ref={this.containerRef}>
						<div id="loading" className={this.state.loaded == true ? "loading loaded" : "loading"}>
							<Layout type="post" left="designer gráfico" showreelModal={this.updateStateModal}>
								<Form />
								<div className="background">
									<div className="gray-bg">
									</div>
								</div>
								<Icons />
							</Layout>

						</div>
					</main>
				</LocomotiveScrollProvider>
				<Cursor />
			</>
		)
	}
}

export default RootIndex

